import "./App.css";
import Nav from "./Components/Nav/Nav";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MenuPage from "./Components/MenuPage/MenuPage";
import HomePage from "./Components/HomePage/HomePage";
import ContactPage from "./Components/ContactPage/ContactPage";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Nav />
        <main style={{marginTop: "100px", padding: "0 20px 0 20px"}}>

        <Routes>
          <Route path="/menu" element={<MenuPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/dove-siamo" element={<External />}></Route>
          <Route path="/" element={<HomePage />}></Route>

          {/*<Route path="/projects" element={<Projects />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="*" element={<Error />}></Route> */}
        </Routes>
        </main>

      </div>
    </Router>
  );
};

function External() {
  window.location.href = 'https://goo.gl/maps/g8fnEmznDvNrGJ7K9';
  return null;
}

export default App;


