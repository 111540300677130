
import { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import { Alert, Collapse } from 'tiny-ui';
// import axios from 'axios';
import 'tiny-ui/dist/styles/index.css';
import './menupage.css'


const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";

const { Panel } = Collapse;

// MENU API DOCS: https://menudigitale.app/api/schema/swagger/#/

const MENU_UID = "tC-QLWYcfqUgqCZ94Kl1WSnfXOvBxb4GOb2lykZbfCc";
const BASEURL = `https://menudigitale.app/api/menus/${MENU_UID}`;

export default function MenuPage() {
  const [sections, setSections] = useState(null);

  const getApiData = async () => {
    const sections = await fetch(`${BASEURL}/sections/?ordering=position`)
      .then((response) => response.json())

    await Promise.all(sections.map(async section => {
      section.groups = await fetch(`${BASEURL}/sections/${section.id}/groups/?ordering=position`).then((response) => response.json())
      // section.groups.sort((a, b) => a.position.localeCompare(b.position))

      for (let group of section.groups) {
        group.items = group.items.filter(item => item.is_available)
        // .sort((a, b) => a.position.localeCompare(b.position))
      }
    }))
    setSections(sections);
  }

  useEffect(() => {
    getApiData()
  }, []);

  if (!sections) return null;

  return(<> <ul>{sections.map(section => {
    return (<li key={section.id}>
      <h2 className='section__container'>{section.name}</h2>
      {section.groups.map(group => {
        return (<Collapse bordered={false} key={group.id}>
          <Panel header={group.name} itemKey='1'>
            <ul>
            {group.items.map(item => {
              return(
              <li key={item.id}>
                <div className='title__container'>
                  <div>
                    <h3>{item.name}</h3>
                  </div>
                  <div>
                    <h3 className='price__container'>{item.price}</h3>
                  </div>
                </div>
                <div className='description__container'>
                  <div className='description_content'>

                    <p className='description__item'>{item.ingredients}</p>
                    <p className='description__item'>{item.description}</p>
                    <div className='labels__container'>
                      <p className='labels__item'>{item.is_vegetarian && ''}</p>
                      <p className='labels__item'>{item.is_gluten_free && 'senza glutine'}</p>
                    </div>
                  </div>
                  {
                    item.image &&
                    <img className='item__image' src={item.image} alt={item.name} />
                  }
                </div>
              </li>
              )
            })}
            </ul>
          </Panel>
        </Collapse>
        )
      })}
    </li>)
  })}</ul>
  <div>
    <h3 className='section__container'>Allergeni</h3>
    <ul className='space-allergeni'>
    <li className='lista-allergeni'><sup>1.</sup>  Cereali</li>
    <li className='lista-allergeni'><sup>2.</sup> Uova</li>
    <li className='lista-allergeni'><sup>3.</sup> Pesce</li>
    <li className='lista-allergeni'><sup>4.</sup> Arachidi</li>
    <li className='lista-allergeni'><sup>5.</sup> Soia </li>
    <li className='lista-allergeni'><sup>6.</sup> Latte</li>
    <li className='lista-allergeni'><sup>7.</sup> Frutta a guscio</li>
    <li className='lista-allergeni'><sup>8.</sup> Senape </li>
    <li className='lista-allergeni'><sup>9.</sup> Anidride Solforosa</li>
    <li className='lista-allergeni'><sup>10.</sup>Sesamo</li>
    </ul>
  </div>
  <div className='space-allergeni'>
  </div>
  </>)
};

