import { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import axios from 'axios';
import './contactpage.css'


export default function ContactPage() {
    return (
        <div>
            <div className='row'>
                <div className='column'>
                    <div className='column-g1'>
                        <h1 className='titolo'>CONTATTI</h1>
                        <h4>Via del Pratello 22c, 40122 Bologna BO</h4>
                        <h4 className='telefono'>Telefono: 051 049 6842</h4>
                        <h4 className='social'>Mail: <a href="mailto:scikenza.snc@gmail.com"> scikenza.snc@gmail.com</a></h4>
                        <h4 className='social'>Seguici: <a href='https://www.instagram.com/macondobologna/'>Instagram</a> <a href='https://www.facebook.com/MacondoBologna'>Facebook</a></h4>
                        <h4 className='social'>Eventi e live music: <a href='mailto:live.macondo@gmail.com'>live.macondo@gmail.com</a></h4>
                        <p className='orari'>Siamo aperti tutti i giorni dalle 18 alle 2.</p>
                    </div>
                </div>
                <div className='column'>
                    <div className='column-g2'>
                    <img className='column-img' src="/img/hamburger-contact.jpg" alt="macondo"/></div>
                </div>
            </div>
            <div>
                    <div className='footer'><div>© Copyright Macondo 2022</div><div>Via del Pratello, 22c, Bologna</div></div>
            </div>
        </div>
    )
}