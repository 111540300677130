import './homepage.css'
import { Link } from "react-router-dom";

import Marquee from "react-fast-marquee";


export default function HomePage() {
  return (
    <div>
      <div className='img-container'>
        <div className='home-caption'><img className='logo-header' src="/img/macondologo.svg" /><br></br><span>COCKTAIL BAR</span></div>
        <img className='header-style' src="/img/macondo-home.jpg" alt="macondo" />
      </div>
      <div className='some-page-wrapper'>
        <div className='row'>
          <div className='column'>
            <div className='column-g1'>
              <h4>Mille mila distillati di qualità, cocktail list estrose, cocktail IBA fatti a modino, birre, vini, amari, liquori vari e addirittura analcolici, panini delicatissimi che ti accelerano la salivazione solo a guardarli, stuzzicherie sfiziose ancora più assai delicatissime, musica dal vivo duecentocinquanta notti all’anno e uno staff che vi vuole bene come allo zio simpatico che fa i discorsi ai matrimoni. Il Macondo è in parte questo, il resto sarà una piacevole, croccante e lillosa scoperta.</h4>
              <h4> Esplora la nostra cocktail list, e lasciati sorprendere dalla nostra cucina.</h4>
              {/* <a href="/menu" className='home-button' type="button">Menu</a> */}
              <Link
                to="/menu"
                className="home-button"
                type="button"
              >
                Menu
              </Link>
            </div>
          </div>
          <div className='column'>
            <div className='column-g2'>
              <img className='column-home-img' src="/img/foto-homemacondo.jpg" alt="macondo" />
            </div>
          </div>
        </div>
      </div>
      <div className='footer'><div>© Copyright Macondo 2022</div><div>Via del Pratello, 22c, Bologna</div></div>
    </div>

  )
}


